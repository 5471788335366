import React from 'react';

function App() {
  return (
    <div style={{ height: '100vh', margin: 0, padding: 0 }}>
      <iframe
        src="https://newa.grape.ag"
        title="Grape.ag & NEWA | Seneca Lake"
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      />
    </div>
  );
}

export default App;
